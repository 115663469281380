<template>
  <div>
    <van-dropdown-menu active-color="#e8b77e">
      <van-dropdown-item v-model="orderBy" :options="orderOptions" />
      <van-dropdown-item @open="openFilter" title="筛选" ref="item">
        <div class="dropdown-top">
          <p class="condition-title">伙伴</p>
          <div class="filter-partner-btn-warp">
            <van-badge :content="activePartners.length ? activePartners.length : ''">
              <div class="btn" @click="() => {
                showPartnerFilter=true;
                showPartnerTooltip=false;
              }">点击筛选伙伴</div>
            </van-badge>
            <div v-if="showPartnerTooltip" class="partner-filter-tooltip">点击筛选</div>
          </div>

          <p class="condition-title">标签</p>
          <div class="full-width-container" v-if="accountTags.length">
            <van-checkbox-group class="checkbox-group" v-model="conditions.tagIds" ref="checkboxGroup">
              <van-checkbox icon-size="16px" checked-color="#e8b77e" shape="square" v-for="tag of accountTags" :key="tag.id" :name="tag.id">{{ tag.tagName }}</van-checkbox>
            </van-checkbox-group>
          </div>

          <p class="condition-title">编号</p>
          <div class="full-width-container">
            <van-field v-model="conditions.number" placeholder="编号" />
          </div>

          <p class="condition-title">关键词筛选</p>
          <div class="full-width-container">
            <van-field v-model="conditions.remark" placeholder="关键词" />
          </div>

          <p class="condition-title">VIP等级区间</p>
          <div class="range-container">
            <van-field v-model="conditions.lowLevel" type="number" placeholder="最低等级" />
            <span class="separator"></span>
            <van-field v-model="conditions.highLevel" type="number" placeholder="最高等级" />
          </div>

          <p class="condition-title">区号筛选</p>
          <div class="full-width-container">
            <van-radio-group class="radio-group" v-model="conditions.zoneType" direction="horizontal">
              <van-radio icon-size="16px" checked-color="#e8b77e" name="limit">限定区</van-radio>
              <van-radio icon-size="16px" checked-color="#e8b77e" name="prefix">区号</van-radio>
              <van-field class="input-with-radio" v-model="conditions.zone" :maxlength="2" type="number" placeholder="区号开头前两位" />
            </van-radio-group>
          </div>

          <p class="condition-title">登录天数</p>
          <div class="range-container">
            <van-field v-model="conditions.lowDays" type="number" placeholder="最少天数" />
            <span class="separator"></span>
            <van-field v-model="conditions.highDays" type="number" placeholder="最多天数" />
          </div>

          <p class="condition-title">战力范围(单位亿)</p>
          <div class="range-container">
            <van-field v-model="conditions.lowPower" type="number" placeholder="最低战力" />
            <span class="separator"></span>
            <van-field v-model="conditions.highPower" type="number" placeholder="最高战力" />
          </div>

          <p class="condition-title">渡劫等级区间</p>
          <div class="range-container">
            <van-field v-model="conditions.lowCatastropheLevel" type="number" placeholder="最少等级" />
            <span class="separator"></span>
            <van-field v-model="conditions.highCatastropheLevel" type="number" placeholder="最高等级" />
          </div>

          <p class="condition-title">金伙伴数量区间</p>
          <div class="range-container">
            <van-field v-model="conditions.lowGolden" type="number" placeholder="最少伙伴数量" />
            <span class="separator"></span>
            <van-field v-model="conditions.highGolden" type="number" placeholder="最多伙伴数量" />
          </div>

          <p class="condition-title">价格区间</p>
          <div class="range-container">
            <van-field v-model="conditions.lowPrice" type="number" placeholder="最低价格" />
            <span class="separator"></span>
            <van-field v-model="conditions.highPrice" type="number" placeholder="最高价格" />
          </div>

          <p class="condition-title">速度范围(单位万)</p>
          <div class="range-container">
            <van-field v-model="conditions.lowSpeed" type="number" placeholder="最低速度" />
            <span class="separator"></span>
            <van-field v-model="conditions.highSpeed" type="number" placeholder="最高速度" />
          </div>

          <p class="condition-title">冰冻范围(单位万)</p>
          <div class="range-container">
            <van-field v-model="conditions.lowFrozen" type="number" placeholder="最低冰冻" />
            <span class="separator"></span>
            <van-field v-model="conditions.highFrozen" type="number" placeholder="最高冰冻" />
          </div>

          <p class="condition-title">法伤范围(单位万)</p>
          <div class="range-container">
            <van-field v-model="conditions.lowMagicDamage" type="number" placeholder="最低法伤" />
            <span class="separator"></span>
            <van-field v-model="conditions.highMagicDamage" type="number" placeholder="最高法伤" />
          </div>

          <p class="condition-title">物伤范围(单位万)</p>
          <div class="range-container">
            <van-field v-model="conditions.lowPhysicalDamage" type="number" placeholder="最低物伤" />
            <span class="separator"></span>
            <van-field v-model="conditions.highPhysicalDamage" type="number" placeholder="最高物伤" />
          </div>

          <p class="condition-title">幸运范围(单位万)</p>
          <div class="range-container">
            <van-field v-model="conditions.lowLucky" type="number" placeholder="最低幸运" />
            <span class="separator"></span>
            <van-field v-model="conditions.highLucky" type="number" placeholder="最高幸运" />
          </div>

          <p class="condition-title">类型</p>
          <div class="full-width-container">
            <van-radio-group class="radio-group" v-model="conditions.accountType" direction="horizontal">
              <van-radio icon-size="16px" checked-color="#e8b77e" name="">全部</van-radio>
              <van-radio icon-size="16px" checked-color="#e8b77e" name="mobile">手机</van-radio>
              <van-radio icon-size="16px" checked-color="#e8b77e" name="mail">邮箱</van-radio>
              <van-radio icon-size="16px" checked-color="#e8b77e" name="wechat">微信</van-radio>
            </van-radio-group>
          </div>

          <p class="condition-title">是否带小号</p>
          <div class="full-width-container">
            <van-radio-group class="radio-group" v-model="conditions.hasTrumpet" direction="horizontal">
              <van-radio icon-size="16px" checked-color="#e8b77e" name="">全部</van-radio>
              <van-radio icon-size="16px" checked-color="#e8b77e" name="true">是</van-radio>
              <van-radio icon-size="16px" checked-color="#e8b77e" name="false">否</van-radio>
            </van-radio-group>
          </div>

          <p class="condition-title">是否榜一</p>
          <div class="full-width-container">
            <van-radio-group class="radio-group" v-model="conditions.isRankOne" direction="horizontal">
              <van-radio icon-size="16px" checked-color="#e8b77e" name="">全部</van-radio>
              <van-radio icon-size="16px" checked-color="#e8b77e" name="true">是</van-radio>
              <van-radio icon-size="16px" checked-color="#e8b77e" name="false">否</van-radio>
            </van-radio-group>
          </div>

<!--          <p class="condition-title">男/女号</p>-->
<!--          <div class="full-width-container">-->
<!--            <van-radio-group class="radio-group" v-model="conditions.gender" direction="horizontal">-->
<!--              <van-radio icon-size="16px" checked-color="#e8b77e" name="">全部</van-radio>-->
<!--              <van-radio icon-size="16px" checked-color="#e8b77e" name="male">男</van-radio>-->
<!--              <van-radio icon-size="16px" checked-color="#e8b77e" name="female">女</van-radio>-->
<!--            </van-radio-group>-->
<!--          </div>-->

        </div>

        <div class="btn-list">
          <div class="custom-btn default-btn" @click="reset">重置</div>
          <div class="custom-btn primary-btn" @click="search">搜索</div>
        </div>
      </van-dropdown-item>
      <div v-if="showFilterTooltip" class="filter-tooltip">点这快速筛选</div>
    </van-dropdown-menu>
    <van-notice-bar left-icon="volume-o" v-if="noticeText" :text=noticeText />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      class="account-list"
      ref="accountList"
      @load="getAccounts"
    >
      <swiper v-if="noticeImgs && noticeImgs.length" :options="swiperOptions">
        <swiper-slide  v-for="(url, index) of noticeImgs" :key="index">
          <img v-lazy="url" :src="url" class="notice-img">
        </swiper-slide>
      </swiper>
<!--      <img :src="noticeImg" v-if="noticeImg" class="notice-img">-->
      <div class="account-card" v-for="(account, index) of accounts" :key="index" @click="toDetail(account)">
        <div class="left">
          <img :src="account.cover" alt="封面">
          <div class="ribbon" v-if="account.ribbon">{{ account.ribbon}}</div>
        </div>
        <div class="right">
          <div class="stick-icon">
            <div v-if="account.newCreate" class="icon green">新</div>
            <div v-if="!!account.seq" class="icon">热</div>
            <div v-if="!!account.redTag" class="icon icon-customer red">{{ account.redTag }}</div>
            <div v-if="!!account.purpleTag" class="icon icon-customer purple">{{ account.purpleTag }}</div>
            <div v-if="!!account.blueTag" class="icon icon-customer blue">{{ account.blueTag }}</div>
            <div v-if="!!account.yellowTag" class="icon icon-customer yellow">{{ account.yellowTag }}</div>
          </div>
          <div class="account-info">
            <p>编号：{{ account.number }}</p>
            <p><span>区号{{ account.zone }}</span><span v-if="account.trumpetNum > 0">带小号</span></p>
            <p><span>V{{ account.vipLevel }}</span><span>战力{{ account.power | powerFormat }}</span></p>
            <p><span>渡劫:{{ account.catastropheLevel }}</span><span>{{ account.goldPartnerNum }}个金伙伴</span></p>
            <p>登录天数：{{ account.openDays }}</p>
            <p>类型：{{ account.accountType | accountType }}</p>
          </div>
          <div class="account-price">{{ parseInt(account.price) }}米</div>
        </div>
      </div>
    </van-list>
    <div @click.prevent.stop>
      <van-popup
          v-model="showPartnerFilter"
          :close-on-click-overlay="false"
          position="top"
          close-icon="close"
          :style="{ height: '4.6rem' }"
          @click.stop
          @click.prevent
          round
      >
        <van-tree-select
            height="4rem"
            :items="partnerItems"
            :active-id.sync="activePartners"
            :main-active-index.sync="activePartnerLevelIndex"
        />
        <div class="btn-list">
          <div class="custom-btn default-btn" @click="() => { activePartners=[]; showPartnerFilter = false; }">清空</div>
          <div class="custom-btn primary-btn" @click="showPartnerFilter=false">确认</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
  import { DropdownMenu, DropdownItem, Field, NoticeBar, List, Radio, RadioGroup, Checkbox, CheckboxGroup, TreeSelect, Popup, Badge } from 'vant';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';
  import { getAccountList, getSettings, getAccountTags, getAllPartners } from './../api/index';

  export default {
    name: 'AccountList',
    components: {
      VanField: Field,
      VanDropdownMenu: DropdownMenu,
      VanDropdownItem: DropdownItem,
      VanNoticeBar: NoticeBar,
      VanList: List,
      VanRadio: Radio,
      VanRadioGroup: RadioGroup,
      VanCheckbox: Checkbox,
      VanCheckboxGroup: CheckboxGroup,
      VanTreeSelect: TreeSelect,
      VanPopup: Popup,
      VanBadge: Badge,
      Swiper,
      SwiperSlide,
    },
    filters: {
      powerFormat(val) {
        if (val > 100000000) {
          return parseFloat((val/100000000).toFixed(2)) + '亿';
        } else if (val > 10000) {
          return parseFloat((val/10000).toFixed(2)) + '万';
        } return val;
      },
      accountType(val) {
        if (val === 'mail') {
          return '邮箱';
        } else if (val === 'mobile') {
          return '手机';
        } else if (val === 'wechat') {
          return '微信';
        } else {
          return '-';
        }
      }
    },
    data() {
      return {
        swiperOptions: {
          autoHeight: true,
          // width: '100%',
          loop: true,
          autoplay: {
            delay: 10000,
          },
        },
        accountTags: [],
        noticeText: '',
        noticeImgs: [],
        limit: 10,
        skip: 0,
        loading: true,
        finished: false,
        orderBy: 'default',
        orderOptions: [
          { text: '默认排序', value: 'default' },
          { text: '最近发布', value: 'time' },
          { text: '价格由高到低', value: 'priceDesc' },
          { text: '价格由低到高', value: 'priceAsc' },
          { text: '战力由高到低', value: 'powerDesc' },
          { text: '战力由低到高', value: 'powerAsc' },
          { text: '物伤由高到低', value: 'ADDesc' },
          { text: '物伤由低到高', value: 'ADAsc' },
          { text: '法伤由高到低', value: 'APDesc' },
          { text: '法伤由低到高', value: 'APAsc' },
          { text: '冰冻由高到低', value: 'frozenDesc' },
          { text: '冰冻由低到高', value: 'frozenAsc' },
          { text: '速度由高到低', value: 'speedDesc' },
          { text: '速度由低到高', value: 'speedAsc' },
          { text: '伙伴数量由高到低', value: 'partnerDesc' },
          { text: '伙伴数量由低到高', value: 'partnerAsc' },
          // { text: 'VIP等级排序', value: 'level' },
        ],
        defaultConditions: {},
        conditions: {
          zoneType: 'prefix',
          remark: '',
          number: '',
          lowLevel: '',
          highLevel: '',
          zone: '',
          lowDays: '',
          highDays: '',
          lowPower: '',
          highPower: '',
          lowCatastropheLevel: '',
          highCatastropheLevel: '',
          lowGolden: '',
          highGolden: '',
          lowSpeed: '',
          highSpeed: '',
          lowFrozen: '',
          highFrozen: '',
          lowMagicDamage: '',
          highMagicDamage: '',
          lowPhysicalDamage: '',
          highPhysicalDamage: '',
          lowLucky: '',
          highLucky: '',
          lowPrice: '',
          highPrice: '',
          accountType: '',
          hasTrumpet: '',
          gender: '',
          isRankOne: '',
          tagIds: [],
        },
        searchConditions: {},
        accounts: [],
        showTypePicker: false,
        historyTop: 0,
        showFilterTooltip: true,

        showPartnerTooltip: true,
        showPartnerFilter: false,
        partnerItems: [],
        activePartners: [],
        activePartnerLevelIndex: 0,
      }
    },
    watch: {
      orderBy: function() {
        this.initData();
      },
      'conditions.zoneType': function(next) {
        if (next === 'limit') {
          this.conditions.zone = '';
        }
      },
      'conditions.zone': function(next) {
        if (next) {
          this.conditions.zoneType = 'prefix';
        }
      },
      'activePartners': function(next) {
        const levelCounts = [0, 0, 0, 0, 0, 0];
        next.forEach(item => {
          const [level] = item.split('-');
          levelCounts[levelCounts.length - level - 1] += 1;
        });
        this.partnerItems.forEach((item, index) => {
          item.badge = levelCounts[index];
          if (item.badge === 0) {
            item.badge = '';
          }
        });
      },
    },
    created() {
      this.defaultConditions = Object.assign({}, this.conditions);
      getAccountTags().then(res => {
        this.accountTags = res;
      });
      getAllPartners().then(res => {
        if (!res) return false;
        const levels = ['5星', '4星', '3星', '2星', '1星', '0星']
        if (res.length) {
          this.partnerItems = levels.map((level) => {
            return {
              text: level,
              // badge: 3,
              children: res.map(item => ({
                text: item.name,
                id: `${level.match(/\d/)[0]}-${item.name}`,
              }))
            }
          })
        }
      });

      getSettings(['AccountText']).then(res => {
        if (!res || !res.length) return false;
        this.noticeText = res[0].text;
        this.noticeImgs = res[0].pictures;
      });
      this.initData();
      setTimeout(() => {
        this.showFilterTooltip = false;
      }, 5000);
    },
    beforeRouteLeave(to, from, next) { // 离开时记录高度
      this.historyTop = this.$refs.accountList.$el.scrollTop;
      next();
    },
    activated() {
      this.$refs.accountList.$el.scrollTop = this.historyTop;
    },
    methods: {
      openFilter() {
        // 打开筛选框
        this.showFilterTooltip = false;
        window.setTimeout(() => {
          this.showPartnerTooltip = false;
        }, 3000);
      },
      onConfirmAccountType(value) {
        this.conditions.accountType = value;
        this.showTypePicker = false;
      },
      reset() {
        Object.assign(this.searchConditions,  this.defaultConditions);
        Object.assign(this.conditions,  this.defaultConditions);

        this.activePartners = [];
        delete this.searchConditions.partners;

        this.$refs.item.toggle();
        this.initData();
      },
      search() {
        Object.assign(this.searchConditions, this.conditions);
        this.searchConditions.partners = this.activePartners.map(item => {
          const pairs = item.split('-');
          return { name: pairs[1], level: pairs[0] };
        });
        Object.assign(this.searchConditions, { });
        this.$refs.item.toggle();
        this.initData();
      },
      initData() {
        this.skip = 0;
        this.finished = false;
        this.accounts = [];
        this.loading = true;
        this.getAccounts();
      },
      async getAccounts() {
        try {
          const accounts = await getAccountList({
            orderBy: this.orderBy,
            skip: this.skip,
            limit: this.limit,
            ...this.searchConditions,
          });
          this.skip += accounts.length;
          this.accounts.push(...(accounts || []));
          if (!accounts || !accounts.length) {
            this.finished = true;
          }
        } catch {
          this.finished = true;
        } finally {
          this.loading = false;
        }
      },
      toDetail(account) {
        this.$router.push({ name: 'accountInfo', params: { id: account.id }});
      }
    }
  }
</script>

<style scoped lang="scss">
  .van-notice-bar {
    font-size: 12px;
    height: 24px;
  }
  ::v-deep .van-dropdown-item__content {
    max-height: 100%;
    .dropdown-top {
      max-height: calc(100vh - 95px);
      overflow: scroll;
    }
  }
  .condition-title {
    margin: 6px 20px 8px;
    font-size: 14px;
    color: #444;
  }
  .notice-img {
    width: 100%;
  }
  .range-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 10px;
    .van-cell {
      width: 45%;
      background: #f7f7f7;
      border-radius: 50px;
      line-height: 14px;
      font-size: 12px;
      padding: 8px 16px;

      ::v-deep input {
        text-align: center !important;
      }

      &:after {
        content: none;
      }
    }
    .separator {
      width: 8px;
      height: 1px;
      background: #cacbce;
    }
  }
  .full-width-container {
    padding: 0 20px 10px;
    .van-cell {
      background: #f7f7f7;
      border-radius: 50px;
      line-height: 14px;
      font-size: 12px;
      padding: 8px 16px;

      ::v-deep input {
        text-align: center !important;
      }

      &:after {
        content: none;
      }
    }
  }
  .radio-group {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .van-radio {
      margin-right: 30px;
    }
  }
  .checkbox-group {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    flex-wrap: wrap;
  }
  .btn-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-top: 1px solid #efefef;
    background: #FFF;
    .custom-btn {
      width: 45%;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 50px;
      font-size: 14px;
      border: 1px solid #eee;
      box-sizing: content-box;
      color: #666;

      &.primary-btn {
        background: #e8b77e;
        color: #FFF;
        border: 1px solid #e8b77e;
      }
    }
  }
  .input-with-radio {
    width: 150px;
    flex: 1;
  }

  .filter-partner-btn-warp {
    text-align: center;
    margin: 0 auto;
    .btn {
      width: 50vw;
      border-radius: 32px;
      text-align: center;
      background: #e8b77e;
      color: #FFF;
      border: 1px solid #e8b77e;
      font-size: 12px;
      padding: 4px 0;
    }
  }

  .account-list {
    height: calc(100vh - 127px);
    overflow: scroll;
  }
  .account-card {
    background: #FFF;
    display: flex;
    position: relative;
    align-items: center;
    margin: 10px 0;
    .left {
      img {
        width: 100px;
        height: 100px;
        display: block;
      }
      margin-right: 15px;
      padding: 5px 0;
      overflow: hidden;
      position: relative;
      .ribbon {
        background: #ff6d4c;
        color: #FFF;
        font-size: 12px;
        position: absolute;
        left: 0;
        top: 12px;
        padding: 0 5px 0 5px;
        text-align: center;
        line-height: 20px;
        &::after {
          content: "";
          position: absolute;
          right: -9px;
          border: 10px solid #ff6d4c;
          border-left: 0;
          border-right: 9px solid transparent;
        }
      }
    }
    .right {
      .stick-icon {
        position: absolute;
        right: 10px;
        left: 240px;
        float: right;
        top: 6px;
        .icon {
          border-radius: 8px;
          background: #eaa24f;
          width: 20px;
          height: 20px;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
          color: #FFF;
          display: inline-block;
          margin-left: 5px;
          padding: 0 3px;
          float: right;
          margin-bottom: 5px;
        }
        .icon-customer {
          width: initial;
        }
        .green {
          background: #a5cf49;
        }
        .red {
          background: #ff6d4c;
        }
        .blue {
          background: #4c8cff;
        }
        .purple {
          background: #8a49cf;
        }
        .yellow {
          background: #d5d343;
        }

      }
      p {
        margin: 0;
        font-size: 12px;
        span {
          margin-right: 14px;
        }
      }
      .account-price {
        position: absolute;
        color: #e8b77e;
        right: 10px;
        bottom: 10px;
      }
    }
  }

  .filter-tooltip {
    position: absolute;
    z-index: 100;
    background: rgba(0,0,0,0.6);
    color: #FFFFFF;
    font-size: 14px;
    padding: 8px;
    border-radius: 6px;
    top: 57px;
    margin-left: calc(75vw - 64px);
    animation-duration: 0.5s;
    animation-name: tooltip-shake;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  .filter-tooltip::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom: 5px solid rgba(0,0,0,0.6);
    position: absolute;
    top: -10px;
    left: 60px;
  }

  .partner-filter-tooltip {
    position: absolute;
    z-index: 100;
    background: rgba(0,0,0,0.6);
    color: #FFFFFF;
    font-size: 14px;
    padding: 8px;
    border-radius: 6px;
    top: 70px;
    margin-left: calc(50vw - 34px);
    animation-duration: 0.5s;
    animation-name: tooltip-shake;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  .partner-filter-tooltip::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom: 5px solid rgba(0,0,0,0.6);
    position: absolute;
    top: -9px;
    left: 34px;
  }
  @keyframes tooltip-shake {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10px);
    }
  }
</style>
